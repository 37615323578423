
section.services-section {
    background-color: #ffffff;

    h2 {
        color: #333333;
    }
    h4 {
        font-family: quicksand, sans-serif;
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .text-fx .mod {
        padding: 70px 0;
        min-height: 206px;
        background-color: #FFFFFF;
        -webkit-transition: background-color 0.35s;
        transition: background-color 0.35s;
    }
    .text-fx h5 {
        font-size: 22px;
        letter-spacing: 0;
        text-transform: capitalize;
        color: #415276;
        font-weight: 400;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(0,65%,0);
        transform: translate3d(0,65%,0);
    }
    .text-fx .mod::before,
    .text-fx p {
        font-size: 16px;
        font-weight: 700;
        color: rgba($color: #FFFFFF, $alpha: 0.66);
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    .text-fx:hover h5 {
        color: #FFFFFF;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
    .text-fx:hover .mod::before,
    .text-fx:hover p {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .35s;
        -moz-transition: all .35s;
        transition: all .35s;
    }
    .text-fx:hover .mod {
        background-color: #0AB2C7;
    }
    
    /* Arrow Animation */

     body[data-button-style^="rounded"] .rr-cta:not([data-style="material"]) .link_wrap {
        border-radius: 200px!important;
        -webkit-border-radius: 200px!important;
        box-shadow: none;
        -ms-transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
        -webkit-transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
        transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
    }

    .rr-cta .link_wrap .link_text{
        padding-right:36px;
        position:relative;
        display:inline-block;
    }

    .rr-cta[data-display="inline"] {
        display: inline-block;
    }

    .rr-cta[data-using-bg="true"] .text {
        margin-right: 10px;
    }

    .rr-cta .link_wrap{
        position:relative;
        text-decoration:inherit;
        font-family: quicksand, sans-serif;
        font-weight: 500;
        font-size: 20px;
    }

    .rr-cta[data-using-bg="true"] .link_wrap {
        cursor: pointer;
    }

    .rr-cta[data-style="see-through"] .link_wrap .arrow:after{
        position:absolute;
        content:'';
        border-bottom:1px solid #000;
        height:1px;
        width:22px;
        right:12px;
        top:50%;
        margin-top:-2px;
        -ms-transform:scale(0,1) translateX(0px);
        -ms-transform-origin:left;
        transform:scale(0,1) translateX(0px);
        transform-origin:left;
        -webkit-transform:scale(0,1) translateX(0px);
        -webkit-transform-origin:left;
        pointer-events:none;
        transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        -webkit-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
    }
    .rr-cta .link_wrap .arrow:before {
        font-family:'Fontawesome';
        font-weight:normal;
        font-style:normal;
        text-decoration:inherit;
        backface-visibility:hidden;
        -webkit-font-smoothing:antialiased;
        content:"\f105";
        color:#000;
        line-height:30px;
        font-size:14px;
        position:absolute;
        right:0;
        width:26px;
        text-align:center;
        height:30px;
        top:50%;
        margin-top:-16px;
        -ms-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        -webkit-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
    }
    .rr-cta[data-text-color="custom"] .link_wrap .arrow:before, 
    .rr-cta[data-text-color="custom"] .arrow:after,  
    .rr-cta[data-style="see-through"][data-text-color="custom"] .link_wrap .arrow:after{
        color:inherit;
        border-color:inherit;
    }
    .rr-cta[data-alignment="left"]{
        text-align:left;
    }
    .rr-cta[data-alignment="center"]{
        text-align:center;
    }
    .rr-cta[data-alignment="right"]{
        text-align:right;
    }
    
    .rr-cta[data-color="black"]:not([data-style="material"]) .link_wrap {
        background-color: #000;
    }
    .rr-cta[data-color="white"]:not([data-style="material"]) .link_wrap {
        background-color: #fff;
    }
    
    .rr-cta .link_wrap .link_text{
        color:inherit;
    }
    .rr-cta > p {
        padding-bottom: 0;
    }
    .rr-cta[data-text-color="custom"] .link_wrap .link_text:after, 
    .rr-cta[data-text-color="custom"] .link_wrap:after{
        border-color:inherit!important;
    }
    .rr-cta[data-text-color="custom"] .link_wrap:before{
        color:inherit!important;
    }
    
    .rr-cta .link_wrap .link_text:after {
        border-radius:100px;
        border:1px solid rgba(0,0,0,0.3);
        height:25px;
        width:25px;
        position:absolute;
        right:0;
        top:50%;
        margin-top:-14px;
        content:' ';
        display:block;
        pointer-events:none;
        -ms-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1);
        -webkit-transition:all 0.33s cubic-bezier(0.12,0.75,0.4,1); 
    }
    .rr-cta[data-style="see-through"] .link_wrap:hover .link_text:after{
        -ms-transform:scale(0.8);
        -webkit-transform:scale(0.8);
        transform:scale(0.8);
        opacity:0;
    }
    .rr-cta[data-style="see-through"] .link_wrap:hover .arrow:after, 
    .rr-cta[data-style="see-through"] .link_wrap:hover .arrow:before{
        -ms-transform:scale(1,1) translateX(9px);
        -webkit-transform:scale(1,1) translateX(9px);
        transform:scale(1,1) translateX(9px);
    }

    .rr-cta[data-style="arrow-animation"] svg,
    .rr-cta[data-style="arrow-animation"] svg polyline {
        stroke: currentColor;
    }

    .rr-cta[data-style="arrow-animation"] svg polyline {
        stroke-dashoffset: 140px;
        stroke-dasharray: 140px;
    }

    .rr-cta[data-style="arrow-animation"] .link_wrap .link_text:after {
        display: none;
    }

    .rr-cta[data-style="arrow-animation"] .link_wrap .link_text {
        padding: 0 0 0 40px;
        position: relative;
    }

    .rr-cta[data-style="arrow-animation"] .link_wrap .link_text > .text {
        display: block;
    }

    .rr-cta[data-style="arrow-animation"] .line {
        height: 1px;
        width: 25px;
        position: absolute;
        content: '';
        left: 0;
        display: block;
        top: 50%;
        transform-origin:left;
        -webkit-transform: translateY(calc(-50% + 0.5px)) translateZ(0);
        transform: translateY(calc(-50% + 0.5px)) translateZ(0);
    }

    .rr-cta[data-style="arrow-animation"] svg {
        position: absolute;
        left: 25px;
        top: 50%;
        width: 6px;
    }
    .team-meta rr-cta[data-style="arrow-animation"] svg {
        left: 24px;
    }

    @keyframes ctaArrowSVGStart {
    0% {
            transform: translateY(calc(-50% + 0.5px)) translateX(0) translateZ(0);
    }
    100% {
            transform: translateY(calc(-50% + 0.5px)) translateX(-3px) translateZ(0);
    }
    }
    @keyframes ctaArrowSVGEnd {
        0% {
            transform: translateY(calc(-50% + 0.5px)) translateX(-3px) translateZ(0);
    }
    100% {
            transform: translateY(calc(-50% + 0.5px)) translateX(0) translateZ(0);
    }
    }

    @keyframes ctaArrowTextStart {
    0% {
        transform: translateX(6px);
    }
    100% {
            transform: translateX(0);
    }
    }
    @keyframes ctaArrowTextEnd {
    0% {
        transform: translateX(0);
    }
    100% {
            transform: translateX(6px);
    }
    }

    @keyframes ctaArrowStart {
        0% {
            stroke-dashoffset: 140px;
            stroke-dasharray: 140px;
        }
        100% {
            stroke-dasharray: 140px;
            stroke-dashoffset: 10px;
        }
    }
    @keyframes ctaArrowStartTeam {
        0% {
            stroke-dashoffset: 0px;
            stroke-dasharray: 0px;
        }
        50% {
            stroke-dashoffset: 140px;
            stroke-dasharray: 140px;
        }
        100% {
            stroke-dasharray: 140px;
            stroke-dashoffset: 10px;
        }
    }
    @keyframes ctaArrowEnd {
        0% {
            stroke-dashoffset: 10px;
            stroke-dasharray: 150px 150px;
    }
        40% {
            opacity: 1;
        }
    100% {
            opacity: 0;
            stroke-dashoffset: -53px;
            stroke-dasharray: 1px 150px;
    }
    }

    @keyframes ctaArrowLineStart {
    0% {
            transform-origin: left;
            transform: scaleX(1.2) translateY(calc(-50% + 0.5px)) translateZ(0);
    }
    100% {
        transform: scaleX(1) translateY(calc(-50% + 0.5px)) translateZ(0);
    }
    }

    @keyframes ctaArrowLineEnd {
    0% {
            transform-origin: right;
            transform: scaleX(1) translateY(calc(-50% + 0.5px)) translateZ(0);
    }
        50% {
            transform-origin: right;
            transform: scaleX(0) translateY(calc(-50% + 0.5px)) translateZ(0);
        }
        51% {
        transform-origin: left;
        }
        
    100% {
        transform: scaleX(1.2) translateY(calc(-50% + 0.5px)) translateZ(0);
            transform-origin: left;
    }
    }

    body[data-button-style^="rounded"] .rr-cta .link_wrap:before {
        border-radius: 200px;
    }
}