.w-100 {
width:100% !important;
}

.py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

@media screen and (min-width: 992px) {.px-lg-30px {
padding-left:30px !important;padding-right:30px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-65px {
padding-left:65px !important;padding-right:65px !important;
}

}
 .mb-0 {
margin-bottom:0px !important;
}

.p-0 {
padding:0px !important;
}

.mr-30px {
margin-right:30px !important;
}

.m-0 {
margin:0px !important;
}

.p-0 {
padding:0px !important;
}

.ml-5px {
margin-left:5px !important;
}

.pt-20px {
padding-top:20px !important;
}

@media screen and (min-width: 992px) {.px-lg-30px {
padding-left:30px !important;padding-right:30px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-65px {
padding-left:65px !important;padding-right:65px !important;
}

}
 .mb-20px {
margin-bottom:20px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.p-0 {
padding:0px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.mr-20px {
margin-right:20px !important;
}

.m-0 {
margin:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.pr-3 {
padding-right:3px !important;
}

.h-100 {
height:100% !important;
}

.pb-15px {
padding-bottom:15px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.py-75px {
padding-top:75px !important;padding-bottom:75px !important;
}

@media screen and (min-width: 992px) {.px-lg-75px {
padding-left:75px !important;padding-right:75px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-100px {
padding-left:100px !important;padding-right:100px !important;
}

}
 .mb-25px {
margin-bottom:25px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mb-60px {
margin-bottom:60px !important;
}

.mb-50px {
margin-bottom:50px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .opacity-90 {
opacity:90 !important;
}

.p-0 {
padding:0px !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.h-100 {
height:100% !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.line-height-22px {
line-height:22px !important;
}

.py-75px {
padding-top:75px !important;padding-bottom:75px !important;
}

.py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

@media screen and (min-width: 992px) {.py-lg-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

}
 .mb-65px {
margin-bottom:65px !important;
}

.mt-40px {
margin-top:40px !important;
}

.py-125px {
padding-top:125px !important;padding-bottom:125px !important;
}

.opacity-80 {
opacity:80 !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mb-60px {
margin-bottom:60px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .p-30px {
padding:30px !important;
}

@media screen and (min-width: 992px) {.p-lg-50px {
padding:50px !important;
}

}
 .mb-25px {
margin-bottom:25px !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mt-20px {
margin-top:20px !important;
}

.pb-25px {
padding-bottom:25px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mt-20px {
margin-top:20px !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.mt-20px {
margin-top:20px !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .font-size-20px {
font-size:20px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.line-height-30px {
line-height:30px !important;
}

.mt-30px {
margin-top:30px !important;
}

.w-100 {
width:100% !important;
}

.bg-800 {
color:800px !important;
}

.py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

.bg-800 {
color:800px !important;
}

@media screen and (min-width: 992px) {.px-lg-45px {
padding-left:45px !important;padding-right:45px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-175px {
padding-left:175px !important;padding-right:175px !important;
}

}
 .pt-10px {
padding-top:10px !important;
}

.font-size-12px {
font-size:12px !important;
}

.pt-10px {
padding-top:10px !important;
}

.font-size-10px {
font-size:10px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mx-5px {
margin-left:5px !important;margin-right:5px !important;
}

@media screen and (min-width: 768px) {.mr-md-0 {
margin-right:0px !important;
}

}
 @media screen and (min-width: 768px) {.ml-md-10px {
margin-left:10px !important;
}

}
 .p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.pt-4 {
padding-top:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-1 {
margin-left:1px !important;margin-right:1px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.w-100 {
width:100% !important;
}

.pt-15px {
padding-top:15px !important;
}

.mr-5px {
margin-right:5px !important;
}

